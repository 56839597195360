import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import Resume from '../../settings/resume.json';
import { LogAmplitudeEvent } from '../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
  iconColor: {
    color: theme.palette.foreground.default,
  },
}));

export const SpeedDials = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    LogAmplitudeEvent('SPEED_DIAL_CLOSED', {});
    setOpen(false);
  };

  const handleOpen = () => {
    LogAmplitudeEvent('SPEED_DIAL_OPENED', {});
    setOpen(true);
  };

  const actionIcons = Resume.basics.profiles.map((action) => (
    <SpeedDialAction
      key={action.network.toLowerCase()}
      icon={<i className={`${action.x_icon} ${classes.iconColor}`}></i>}
      tooltipTitle={action.network}
      onClick={handleClose}
      href={action.url}
      target='_blank'
      rel='noopener noreferrer'
      underline='none'
      color='inherit'
    />
  ));

  return (
    <>
      <SpeedDial
        ariaLabel='SpeedDial'
        className={classes.speedDial}
        hidden={false}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction='down'>
        {actionIcons}
      </SpeedDial>
    </>
  );
};
