import React, { useEffect } from 'react';
import { useDencrypt } from 'use-dencrypt-effect';

const decryptOptions = {
  chars: [
    '-',
    '.',
    '/',
    '*',
    '!',
    '?',
    '#',
    '%',
    '&',
    '@',
    '$',
    '€',
    '(',
    ')',
    '[',
    ']',
    '{',
    '}',
    '<',
    '>',
    '~',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ],
  interval: 50,
};

export const TextDecrypt = (props) => {
  const { result, dencrypt } = useDencrypt(decryptOptions);

  useEffect(() => {
    const updateText = () => {
      dencrypt(props.text || '');
    };

    const action = setTimeout(updateText, 0);

    return () => clearTimeout(action);
  }, [dencrypt, props.text]);

  return (
    <p style={{ margin: '0px' }}>
      {result}
      {' '}
    </p>
  );
};
