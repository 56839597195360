import React from 'react';
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextDecrypt } from './TextDecrypt';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    margin: '0',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    marginLeft: theme.spacing(30),
    '@media (max-width: 1024px)': {
      marginLeft: theme.spacing(15),
    },
    '@media (max-width: 768px)': {
      marginLeft: theme.spacing(5),
    },
  },
  jobs: {
    '@media (max-width: 768px)': {
      fontSize: '3rem',
    },
  },
}));

export const Content = () => {
  const classes = useStyles();

  return (
    <Container component='main' className={classes.main} maxWidth='md'>
      <div className={classes.heading}>
        <Typography variant='h5' component='h2'>
          <div
            data-aos='fade-left'
            data-aos-offset='0'
            data-aos-delay='200'
            data-aos-duration='500'>
            Hi, my name is
          </div>
        </Typography>
        <Typography
          variant='h1'
          component='h1'
          className={classes.jobs}
          style={{ marginLeft: '-5px' }}>
          <div
            data-aos-offset='0'
            data-aos='fade-left'
            data-aos-delay='400'
            data-aos-duration='500'>
            Ribin Roy
          </div>
        </Typography>
        <Typography variant='h5' component='h2'>
          <div
            data-aos-offset='0'
            data-aos='fade-left'
            data-aos-delay='600'
            data-aos-duration='500'>
            and I am a Frontend Developer (by choice)
          </div>
        </Typography>
      </div>
    </Container>
  );
};
