import React, { useEffect, useMemo } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { HelmetMeta } from './HelmetMeta';
import { ThemeProvider } from '../components/theme/ThemeProvider';
import { CssBaseline } from '@material-ui/core';
import { logCredits } from '../utils/logCredits';
import { initAmplitude } from '../utils/amplitude';

import { Home } from '../pages/Home';

export const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  useMemo(() => {
    initAmplitude({
      key: '7c133750579f9d133d1ba2cc8168a197',
      // groupData: {
      //   group_id: 1,
      // },
    });
  }, []);

  logCredits();

  return (
    <ThemeProvider>
      <CssBaseline />
      <Router>
        <HelmetMeta />
        <Switch>
          <Route path='/' exact component={Home} />
          <Redirect from='*' to='/' />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
