import React, { useContext } from 'react';
import { Link, Tooltip, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Resume from '../../settings/resume.json';
import { ThemeContext } from '../theme/ThemeProvider';
import { Logo } from './Logo';
import { LogAmplitudeEvent } from '../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  svg: {
    position: 'fixed',
    width: '50px',
    height: '50px',
    top: theme.spacing(6),
    left: theme.spacing(6),
    '@media (max-width: 768px)': {
      left: theme.spacing(3),
      top: theme.spacing(3),
    },
    // boxShadow:
    //   '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    // borderRadius: "50%",
  },
}));

export const LogoLink = () => {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles();

  return (
    <Tooltip
      title={Resume.basics.name}
      placement='right'
      TransitionComponent={Zoom}>
      <Link
        onClick={() => {
          LogAmplitudeEvent('HEADER_RR_CLICKED', {});
        }}
        data-aos-offset='0'
        data-aos='fade-right'
        data-aos-delay='1200'
        data-aos-duration='800'
        variant='h6'
        href={Resume.basics.url}
        underline='none'
        color='inherit'
        noWrap
        className={classes.svg}>
        <Logo isDark={theme !== 'light'} />
      </Link>
    </Tooltip>
  );
};
