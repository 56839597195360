import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { TextDecrypt } from '../content/TextDecrypt';
import ResumePDF from './../../assets/Ribin Roy Resume.pdf';
import { ResumeIcon } from '../content/ResumeButton';
import { LogAmplitudeEvent } from '../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  footerText: {
    position: 'fixed',
    bottom: theme.spacing(6),
    left: theme.spacing(6),
    '&:hover': {
      color: theme.palette.primary.main,
    },
    transition: 'all 0.5s ease',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      left: theme.spacing(3),
      bottom: theme.spacing(3),
    },
  },
}));

export const Resume = () => {
  const classes = useStyles();

  return (
    <Link
      onClick={() => {
        LogAmplitudeEvent('RESUME_DOWNLOADED', {});
      }}
      data-aos-offset='-5000'
      data-aos='fade-right'
      data-aos-delay='1400'
      data-aos-duration='800'
      color='inherit'
      underline='none'
      href={`${ResumePDF}`}
      target='_blank'
      rel='noopener noreferrer'
      className={classes.footerText}>
      {/* <ResumeIcon /> */}
      <Typography component='span'>
        <TextDecrypt text={' Resume'} />
      </Typography>
    </Link>
  );
};
