/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { LogoLink } from '../components/logo/LogoLink';
import { Content } from '../components/content/Content';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisplacementSphere from '../components/background/DisplacementSphere';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { Resume } from '../components/resume/Resume';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';
import { SideNavbar } from '../components/nav/SideNavbar';
import { Works } from '../components/works/Works';
import { About } from '../components/about/About';
import { Contact } from '../components/contact/Contact';
import ReactFluidScrollComponent from '../components/shared/ReactFluidScroll';
import { LogAmplitudeEvent } from '../utils/amplitude';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

export const Home = () => {
  const classes = useStyles();

  useEffect(() => {
    LogAmplitudeEvent('PAGE_LOADED', {});
  }, []);

  return (
    <>
      <LogoLink />
      <DisplacementSphere />
      <Hidden smDown>
        <SocialIcons />
      </Hidden>
      <Hidden mdUp>
        <SpeedDials />
      </Hidden>
      <Resume />
      <SideNavbar />
      <div className={classes.root} id='home'>
        <Content />
        <Contact />
        {/* <ThemeToggle /> */}
      </div>
      {/* <Works /> */}
      {/* <About /> */}
    </>
  );
};
