/* eslint-disable no-unused-vars */
import React from 'react';
import { useRef } from 'react';
import { Container, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextDecrypt } from '../content/TextDecrypt';
import Swal from 'sweetalert2';
import { LogAmplitudeEvent } from '../../utils/amplitude';

import emailjs from '@emailjs/browser';

import './Contact.css';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    margin: '0',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  formfield: {
    width: '100%',
    marginBottom: '2rem',
  },
}));

export const Contact = () => {
  const classes = useStyles();
  const greetings = 'Say hello.';

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    debugger;
    if (form.current[2].value === '') {
      LogAmplitudeEvent('MESSAGE_EMPTY_IGNORED', {});
      return;
    }

    LogAmplitudeEvent('MESSAGE_SENT', {});
    emailjs
      .sendForm(
        'service_tlhcydv',
        'template_e4q4yf3',
        form.current,
        'CPVYje7q-yA21f5kq'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Thank you! I shall contact you soon!',
      showConfirmButton: false,
      timer: 1500,
    });
    e.target.reset();
  };

  return (
    <section id='contact'>
      <Container component='main' className={classes.main} maxWidth='md'>
        <div className='contact'>
          <h1
            className='contact_msg'
            data-aos-offset='0'
            data-aos='fade-up'
            data-aos-delay='0'
            data-aos-duration='800'>
            <div>{greetings}</div>
          </h1>
          <div
            className='_form_wrapper'
            data-aos-offset='0'
            data-aos='fade-up'
            data-aos-delay='200'
            data-aos-duration='800'>
            <form ref={form} onSubmit={sendEmail} className={classes.form}>
              <TextField
                id='outlined-name-input'
                label='Name'
                type='text'
                size='small'
                variant='filled'
                name='name'
                className={classes.formfield}
              />
              <TextField
                id='outlined-email-input'
                label='Email'
                type='email'
                size='small'
                variant='filled'
                name='email'
                className={classes.formfield}
              />
              <TextField
                id='outlined-message-input'
                label='Message'
                type='textarea'
                size='small'
                multiline
                minRows={5}
                variant='filled'
                name='message'
                className={classes.formfield}
              />
              <button type='submit' value='Send' className='submit-btn'>
                <i className='fas fa-terminal'></i>
                <Typography component='span'> Send Message</Typography>
              </button>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};
