/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Typography } from '@material-ui/core';
import { useState } from 'react';
import { LogAmplitudeEvent } from '../../utils/amplitude';

import './SideNavbar.css';

export const SideNavbar = () => {
  const [activeNav, setActiveNav] = useState('#');

  return (
    <nav>
      <div data-aos='fade-top' data-aos-delay='1300' data-aos-duration='800'>
        <a
          href='#'
          onClick={() => {
            setActiveNav('#');
            LogAmplitudeEvent('HOME_NAV_CLICKED', {});
          }}
          className={activeNav === '#' ? 'active' : ''}>
          <Typography>Home</Typography>
        </a>
        {/* <a href="#works"
        onClick={() => setActiveNav('#works')}
        className={activeNav === '#works' ? 'active' : ''}
      >
        <Typography>
          Projects
        </Typography>
      </a> */}
        {/* <a href="#about" 
        onClick={() => setActiveNav('#about')}
        className={activeNav === '#about' ? 'active' : ''}
      >
        <Typography>
          About
        </Typography>
      </a> */}
        <a
          href='#contact'
          onClick={() => {
            setActiveNav('#contact');
            LogAmplitudeEvent('CONTACT_NAV_CLICKED', {});
          }}
          className={activeNav === '#contact' ? 'active' : ''}>
          <Typography>Contact</Typography>
        </a>
      </div>
    </nav>
  );
};
